import _ from 'lodash';

import {
    selectDiscountCode,
    selectChosenXselling,
    selectPayByPointsItems,
} from '../redux/shop/order/order.selectors';
import { groupBy, pickFields } from './data-convertion';

export const prepareOrderData = (state) => {
    const { buyingProcess, shop } = state;

    const wantInvoice = buyingProcess.stepTwo.wantInvoice;
    const addressData = buyingProcess.stepTwo.addressDelivery;
    const invoiceData = buyingProcess.stepTwo.addressInvoice;
    const providerId = shop.order.delivery.current;
    const registerUser = shop.order.registerUser;
    const userData = shop.order.userData;

    const orderData = {};

    orderData.consents = state.shop.order.consents;

    if (shop.cart) {
        // TEMPORARY START - sending price causes bugs - chudy

        // orderData.price = shop.cart.price;
        orderData.items = shop.cart.items;

        // TEMPORARY END
    }

    if (!_.isEmpty(addressData)) {
        orderData.delivery = {
            providerId,
            saveInUserAddresses: addressData.save,
            address: addressData,
        };
    }

    if (wantInvoice) {
        orderData.invoice = {
            saveInUserAddresses: invoiceData.save,
            address: invoiceData,
        };
    }

    // TEMPORARY START - to send valid order providerId is needed - chudy

    orderData.payment = { providerId: 3 };

    // TEMPORARY END

    orderData.discountCode = selectDiscountCode(state);

    orderData.chosenXselling = selectChosenXselling(state);

    orderData.productIdsForPointsPayment = prepareIdsForPointsPayment(state);

    orderData.registerUser = registerUser;

    orderData.userData = userData;

    return orderData;
};

function prepareIdsForPointsPayment(state) {
    const checkedItems = Object.values(selectPayByPointsItems(state))
        .filter((item) => item.checked)
        .map(pickFields(['productId', 'promotionId']));

    return Object.entries(groupBy(checkedItems, 'promotionId')).reduce(
        (acc, [promotionId, items]) => {
            return [
                ...acc,
                {
                    promotionId,
                    productsIds: items.map((item) => item.productId),
                },
            ];
        },
        []
    );
}
