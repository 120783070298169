import React from 'react';

import {
    box,
    boxName,
    container,
    green,
    large,
    priceRow,
    strike,
    summary,
    value,
} from './shop-step-order-summary.module.scss';
import { ICartItem, ISubscriptionSp } from '../../models/cart.model';
import { config } from '../../config';
import Separator from '../atoms/separator';

interface IShopStepSummaryProps {
    className?: string;
    cartItems: ICartItem[];
}

// 😭
const BASE_STANDARD_INDIVIDUAL_PRICE_GROSS_INTEGER = Number(
    process.env.BASE_STANDARD_INDIVIDUAL_PRICE_GROSS_INTEGER
);
const BASE_PREMIUM_INDIVIDUAL_PRICE_GROSS_INTEGER = Number(
    process.env.BASE_PREMIUM_INDIVIDUAL_PRICE_GROSS_INTEGER
);
const BASE_PREMIUM_PLUS_INDIVIDUAL_PRICE_GROSS_INTEGER = Number(
    process.env.BASE_PREMIUM_PLUS_INDIVIDUAL_PRICE_GROSS_INTEGER
);
const BASE_STANDARD_COUPLE_PRICE_GROSS_INTEGER = Number(
    process.env.BASE_STANDARD_COUPLE_PRICE_GROSS_INTEGER
);
const BASE_PREMIUM_COUPLE_PRICE_GROSS_INTEGER = Number(
    process.env.BASE_PREMIUM_COUPLE_PRICE_GROSS_INTEGER
);
const BASE_PREMIUM_PLUS_COUPLE_PRICE_GROSS_INTEGER = Number(
    process.env.BASE_PREMIUM_PLUS_COUPLE_PRICE_GROSS_INTEGER
);

const ShopStepOrderSummary: React.FC<IShopStepSummaryProps> = ({ className = '', cartItems }) => {
    const installmentsItems = cartItems.filter(
        (item) =>
            item.product.paymentModel === config.paymentModelsMap.installments.id ||
            item.product.paymentModel === config.paymentModelsMap.renewableInstallments.id
    );
    const singlePaymentItems = cartItems.filter(
        (item) => item.product.paymentModel === config.paymentModelsMap.singlePayment.id
    );
    const singlePaymentBasePriceInteger = singlePaymentItems.reduce((acc, item) => {
        return acc + item.basePriceTotal.grossInteger;
    }, 0);
    const singlePaymentPriceInteger = singlePaymentItems.reduce((acc, item) => {
        return acc + item.priceTotal.grossInteger;
    }, 0);
    const cartBasePriceInteger = cartItems.reduce((acc, item) => {
        if (item.subscriptionSp) {
            return acc + getSubscriptionSpMaxPriceInteger(item.subscriptionSp);
        }
        return acc + item.basePriceTotal.grossInteger;
    }, 0);
    const cartPriceInteger = cartItems.reduce((acc, item) => {
        if (item.subscriptionSp) {
            return (
                acc + item.subscriptionSp.price.grossInteger * item.subscriptionSp.installmentCount
            );
        }
        return acc + item.priceTotal.grossInteger;
    }, 0);
    const cartDiscountInteger = cartBasePriceInteger - cartPriceInteger;
    const nowToPayInteger = cartItems.reduce((acc, item) => {
        if (item.subscriptionSp) {
            return acc + item.subscriptionSp.price.grossInteger;
        }
        return acc + item.priceTotal.grossInteger;
    }, 0);
    const monthlyToPayInteger = installmentsItems.reduce((acc, item) => {
        if (item.subscriptionSp) {
            if (item.subscriptionSp.installmentCount > 1) {
                return acc + item.subscriptionSp.price.grossInteger;
            }
            return acc;
        }
        return acc + item.priceTotal.grossInteger;
    }, 0);

    return (
        <div className={`${container} ${className}`}>
            {installmentsItems.length > 0 &&
                installmentsItems.map((item) => {
                    let basePriceInteger;
                    let priceInteger;
                    let discountInteger;
                    let installmentDisplay;
                    if (item.subscriptionSp) {
                        basePriceInteger = getSubscriptionSpMaxPriceInteger(item.subscriptionSp);
                        priceInteger =
                            item.subscriptionSp.price.grossInteger *
                            item.subscriptionSp.installmentCount;
                        discountInteger = basePriceInteger - priceInteger;
                        if (item.subscriptionSp.installmentCount > 1) {
                            installmentDisplay = item.subscriptionSp.price.grossDisplay;
                        }
                    } else {
                        basePriceInteger = item.basePriceTotal.grossInteger;
                        priceInteger = item.priceTotal.grossInteger;
                        discountInteger = basePriceInteger - priceInteger;
                        installmentDisplay = item.priceTotal.grossDisplay;
                    }
                    return discountInteger > 0 ? (
                        <div className={box}>
                            <p className={boxName}>{item.product.name}</p>
                            <div className={priceRow}>
                                <p>Cena przed zniżką:</p>
                                <p className={`${value} ${strike}`}>{basePriceInteger / 100} zł</p>
                            </div>
                            <div className={priceRow}>
                                <p>Twoja zniżka:</p>
                                <p className={`${value} ${green}`}>{discountInteger / 100} zł</p>
                            </div>
                            <div className={priceRow}>
                                <p>Ostateczna cena po zniżce:</p>
                                <p className={`${value} ${green}`}>{priceInteger / 100} zł</p>
                            </div>
                            {installmentDisplay && (
                                <div className={priceRow}>
                                    <p>
                                        {item.subscriptionSp ? 'Miesięczna rata:' : 'Miesięcznie:'}
                                    </p>
                                    <p className={`${value} ${green} ${large}`}>
                                        {installmentDisplay}
                                    </p>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={box}>
                            <p className={boxName}>{item.product.name}</p>
                            <div className={priceRow}>
                                <p>Cena:</p>
                                <p className={value}>{item.priceTotal.grossDisplay}</p>
                            </div>
                            {installmentDisplay && (
                                <div className={priceRow}>
                                    <p>
                                        {item.subscriptionSp ? 'Miesięczna rata:' : 'Miesięcznie:'}
                                    </p>
                                    <p className={`${value} ${large}`}>{installmentDisplay}</p>
                                </div>
                            )}
                        </div>
                    );
                })}
            {singlePaymentItems.length > 0 && (
                <div className={box}>
                    <p className={boxName}>Produkty jednorazowe:</p>
                    {singlePaymentBasePriceInteger - singlePaymentPriceInteger > 0 ? (
                        <>
                            <div className={priceRow}>
                                <p>Cena przed zniżką:</p>
                                <p className={`${value} ${strike}`}>
                                    {singlePaymentBasePriceInteger / 100} zł
                                </p>
                            </div>
                            <div className={priceRow}>
                                <p>Twoja zniżka:</p>
                                <p className={`${value} ${green}`}>
                                    {(singlePaymentBasePriceInteger - singlePaymentPriceInteger) /
                                        100}{' '}
                                    zł
                                </p>
                            </div>
                            <div className={priceRow}>
                                <p>Ostateczna cena po zniżce:</p>
                                <p className={`${value} ${green}`}>
                                    {singlePaymentPriceInteger / 100} zł
                                </p>
                            </div>
                        </>
                    ) : (
                        <div className={priceRow}>
                            <p>Cena:</p>
                            <p className={value}>{singlePaymentPriceInteger / 100} zł</p>
                        </div>
                    )}
                </div>
            )}
            <Separator />
            <div className={`${box} ${summary}`}>
                <p className={boxName}>Łączna wartość zamówienia:</p>
                {cartDiscountInteger > 0 ? (
                    installmentsItems.length > 0 && monthlyToPayInteger > 0 ? (
                        <>
                            <div className={priceRow}>
                                <p>Przed zniżką:</p>
                                <p className={strike}>{cartBasePriceInteger / 100} zł</p>
                            </div>
                            <div className={priceRow}>
                                <p>Po zniżce:</p>
                                <p className={`${value} ${green}`}>{cartPriceInteger / 100} zł</p>
                            </div>
                            <div className={priceRow}>
                                <p>Do zapłaty teraz:</p>
                                <p className={`${value} ${green} ${large}`}>
                                    {nowToPayInteger / 100} zł
                                </p>
                            </div>
                            <div className={priceRow}>
                                <p>
                                    Miesięcznie (tylko za{' '}
                                    {installmentsItems.map((item) => item.product.name).join(', ')}
                                    ):
                                </p>
                                <p className={`${value} ${green}`}>
                                    {monthlyToPayInteger / 100} zł
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={priceRow}>
                                <p>Przed zniżką:</p>
                                <p className={strike}>{cartBasePriceInteger / 100} zł</p>
                            </div>
                            <div className={priceRow}>
                                <p>Do zapłaty po zniżce:</p>
                                <p className={`${value} ${green} ${large}`}>
                                    {cartPriceInteger / 100} zł
                                </p>
                            </div>
                        </>
                    )
                ) : installmentsItems.length > 0 && monthlyToPayInteger > 0 ? (
                    <>
                        <div className={priceRow}>
                            <p>Do zapłaty teraz:</p>
                            <p className={`${value} ${green} ${large}`}>
                                {nowToPayInteger / 100} zł
                            </p>
                        </div>
                        <div className={priceRow}>
                            <p>
                                Miesięcznie (tylko za{' '}
                                {installmentsItems.map((item) => item.product.name).join(', ')}
                                ):
                            </p>
                            <p className={`${value} ${green}`}>{monthlyToPayInteger / 100} zł</p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={priceRow}>
                            <p>Do zapłaty:</p>
                            <p className={`${value} ${large}`}>{cartPriceInteger / 100} zł</p>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

function getSubscriptionSpMaxPriceInteger(subscriptionSp: ISubscriptionSp) {
    let basePriceGrossInteger;
    if (subscriptionSp.isSingle) {
        if (subscriptionSp.isStandard) {
            basePriceGrossInteger = BASE_STANDARD_INDIVIDUAL_PRICE_GROSS_INTEGER;
        } else if (subscriptionSp.isPremium) {
            basePriceGrossInteger = BASE_PREMIUM_INDIVIDUAL_PRICE_GROSS_INTEGER;
        } else {
            basePriceGrossInteger = BASE_PREMIUM_PLUS_INDIVIDUAL_PRICE_GROSS_INTEGER;
        }
    } else {
        if (subscriptionSp.isStandard) {
            basePriceGrossInteger = BASE_STANDARD_COUPLE_PRICE_GROSS_INTEGER;
        } else if (subscriptionSp.isPremium) {
            basePriceGrossInteger = BASE_PREMIUM_COUPLE_PRICE_GROSS_INTEGER;
        } else {
            basePriceGrossInteger = BASE_PREMIUM_PLUS_COUPLE_PRICE_GROSS_INTEGER;
        }
    }
    return basePriceGrossInteger * subscriptionSp.monthsCount;
}

export default ShopStepOrderSummary;
