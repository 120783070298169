// extracted by mini-css-extract-plugin
export var addressDataWrapper = "shop-step-summary-style-module--address-data-wrapper--yzhfG";
export var cartList = "shop-step-summary-style-module--cart-list--7majx";
export var checkbox = "shop-step-summary-style-module--checkbox--h14K3";
export var editButton = "shop-step-summary-style-module--edit-button--H9X-u";
export var form = "shop-step-summary-style-module--form--fR-IX";
export var formDisclaimer = "shop-step-summary-style-module--form-disclaimer--VP6pB";
export var paymentMethodsWrapper = "shop-step-summary-style-module--payment-methods-wrapper--Mrysr";
export var paymentPreview = "shop-step-summary-style-module--payment-preview--zLQVy";
export var paymentSpace = "shop-step-summary-style-module--payment-space--FJpMO";
export var registerForm = "shop-step-summary-style-module--register-form--U8aL+";
export var registerFormContainer = "shop-step-summary-style-module--register-form-container---ctMp";
export var rodoNote = "shop-step-summary-style-module--rodo-note--WllkJ";
export var smallTitle = "shop-step-summary-style-module--small-title--eNZm0";
export var submitButton = "shop-step-summary-style-module--submit-button--9u42T";
export var subtitleWrapper = "shop-step-summary-style-module--subtitle-wrapper--39Woe";
export var summary = "shop-step-summary-style-module--summary--yYgni";
export var title = "shop-step-summary-style-module--title--OStTG";
export var wrapper = "shop-step-summary-style-module--wrapper---xmGR";