import { ICartItem } from '../models/cart.model';
import { EProductKind } from '../models/product-kind.enum';
import { getAbsolutePath, ORDER_ROUTES } from '../routes';

export function getAddToCartRedirectUrl(productKind: EProductKind, cartItems: ICartItem[] = []) {
    if (
        (productKind === EProductKind.Subscription || productKind === EProductKind.Digital) &&
        cartItems.every((item) => item.product.kind !== EProductKind.Physical)
    ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return getAbsolutePath('ORDER_STEPS', ORDER_ROUTES);
    } else {
        return /koszyk/;
    }
}
