import { types as orderTypes } from '../shop/order/order.actions';
import {
    buyingProcessGlobalActions,
    setBuyingProcessGlobalStep,
    validateOrderProcess,
} from './global/actions/buying-process-global-actions';
import { clearAllAlerts } from '../alerts/alerts.actions';
import { ShopStepsEnum } from '../../models/shop-steps.enum';
import { selectChosenStepId } from './global/selectors/buying-process-global-selectors';

const buyingProcessMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;

    if (type !== buyingProcessGlobalActions.setStep) {
        next(action);
    }

    const currentStep = selectChosenStepId(getState());

    switch (type) {
        case buyingProcessGlobalActions.validateStep:
            if (currentStep > ShopStepsEnum.ClientDataDeliveryAndPayment) {
                dispatch(validateOrderProcess(currentStep));
            }
            break;
        case buyingProcessGlobalActions.setStep:
            if (meta.validate && payload > ShopStepsEnum.ClientDataDeliveryAndPayment) {
                dispatch(validateOrderProcess(payload, { nextStep: payload }));
            } else {
                next(action);
            }
            break;
        case orderTypes.SEND_ORDER_VALIDATE_SUCCESS:
            dispatch(clearAllAlerts());
            if (meta?.previousAction?.meta?.nextStep) {
                dispatch(setBuyingProcessGlobalStep(meta.previousAction.meta.nextStep, false));
            }
            break;
        // no default
    }
};

export default buyingProcessMiddleware;
