import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from '@reach/router';

import {
    wrapper,
    banner,
    bannerContent,
    tag,
    separator,
    titleCategoryWrapper,
    currentCategoryTittle,
    categoryCoursePillsHolder,
    currentCategoryDescription,
    dataToDownloadItems,
    dataToDownloadTitle,
    dataToDownloadWrapper,
    info,
    movieAdditionalInformation,
    movieCourseDescription,
    movieGroup,
    subTitle,
    bannerRatioClass,
    loader,
    downloadItem,
    restrictedFileButton,
} from './course.module.scss';
import { mainGrid } from '../../../style/grid.module.scss';
import IconLock from '../../../assets/images/svg/icon-lock.svg';
import {
    clearCourse,
    fetchCourse,
    setChosenCategory,
    setChosenMovie,
    setCourseId,
} from '../../../redux/current-course/actions/current-course.actions';
import { addModalAction } from '../../../redux/actions/actions-modals';
import useCustomAddToCart from '../../../hooks/use-custom-add-to-cart';

import MainBanner from '../main-banner';
import Tag from '../../atoms/tag';
import BigTitle from '../../atoms/big-title';
import Loader from '../../atoms/loader';
import Separator from '../../atoms/separator';
import Main from '../../../layouts/main';
import CategoryCoursePill from '../../atoms/category-course-pill/category-course-pill';
import DownloadBlock from '../../molecules/download-block/download-block';
import CoursesMovieGroup from '../../molecules/courses-movie-group/courses-movie-group';
import Markdown from '../../hoc/markdown';
import { getAddToCartRedirectUrl } from '../../../utills/get-add-to-cart-redirect-url';
import { EProductKind } from '../../../models/product-kind.enum';
import { selectCartItems } from '../../../redux/shop/cart/cart.selectors';

const Course = ({ id }) => {
    const dispatch = useDispatch();
    const selectedCourseState = useSelector((state) => {
        return state.currentCourse.currentCourse;
    });

    const [initialCourseChosen, setInitialCourseIsChosen] = useState(false);

    const {
        name,
        courseDescription,
        coverListUri,
        clues,
        categories,
        slug,
        typeLabel,
        productId,
        canBuy,
        price,
    } = selectedCourseState.data;

    const { addToCart } = useCustomAddToCart(productId);
    const cartItems = useSelector(selectCartItems);

    const movieData = selectedCourseState.chosenMovie;

    const currentCategory = useSelector((state) => {
        return state.currentCourse.currentCourse.chosenCategoryData;
    });

    const { loading } = selectedCourseState.meta;

    const isData = !!selectedCourseState.data.name;

    const bannerData = {
        ratioImage: {
            src: coverListUri,
            ratioClass: bannerRatioClass,
        },
    };

    const handleAddToCart = () => {
        if (!canBuy) {
            dispatch(
                addModalAction({
                    title: 'Produkt chwilowo niedostępny w sprzedaży',
                })
            );
            return;
        }
        const redirectUrl = getAddToCartRedirectUrl(EProductKind.Digital, cartItems);
        addToCart({ productId }, { redirectUrl: redirectUrl });
    };

    const handleShowAddToCartModal = () => {
        dispatch(
            addModalAction({
                title: 'Drogi użytkowniku',
                content:
                    'Usługa, którą wybrałeś jest płatna. Wykup dostęp aby zobaczyć wszystkie materiały.',
                runOnCloseOnlyOnButtonClick: true,
                buttonText: `Kup ten kurs za ${price.grossDisplay}`,
                onClose: handleAddToCart,
                noEmotics: true,
            })
        );
    };

    const handleNavigateToProduct = () => {
        navigate(`/sklep/${slug}/`);
    };

    useEffect(() => {
        dispatch(setCourseId(id));
        dispatch(fetchCourse());

        return () => {
            dispatch(clearCourse());
        };
    }, [dispatch, id]);

    useEffect(() => {
        if (!initialCourseChosen && categories?.length > 0) {
            dispatch(setChosenCategory(categories[0].id));
            setInitialCourseIsChosen(true);
        }
    }, [dispatch, categories, initialCourseChosen, selectedCourseState]);

    useEffect(() => {
        const chosenCategoryMovies = selectedCourseState.chosenCategoryData.movies;
        chosenCategoryMovies.length > 0 && dispatch(setChosenMovie(chosenCategoryMovies[0]));
    }, [
        dispatch,
        selectedCourseState.chosenCategoryData.id,
        selectedCourseState.chosenCategoryData.movies,
    ]);

    if (loading) {
        return <Loader className={loader} />;
    }

    return (
        <Main transparentHeader className={`${wrapper} ${mainGrid}`}>
            <MainBanner
                bannerData={bannerData}
                className={banner}
                imgStyle={{ objectPosition: 'top center' }}
                alt="Banner kursów"
            >
                {isData && (
                    <div className={bannerContent}>
                        <Tag
                            className={tag}
                            tag={{
                                tagId: 1,
                                color: '#481A9B',
                                name: typeLabel,
                            }}
                        />
                        <BigTitle Tag="h1">{name}</BigTitle>
                    </div>
                )}
                {!isData && !loading && (
                    <div className={bannerContent}>
                        <Tag
                            className={tag}
                            tag={{
                                tagId: 1,
                                color: '#481a9b',
                                name: 'Kurs',
                            }}
                        />
                        <BigTitle Tag="h1">Ups! Nie znaleźliśmy takiego kursu</BigTitle>
                    </div>
                )}
            </MainBanner>
            {isData && (
                <>
                    <ul className={categoryCoursePillsHolder}>
                        {categories?.length > 0 &&
                            categories.map((category, index) => {
                                return (
                                    <CategoryCoursePill
                                        key={`category-course-pill--${index}-${category.id}`}
                                        title={category.name}
                                        id={category.id}
                                        listCounter={category.movies.length}
                                    />
                                );
                            })}
                    </ul>

                    <div className={titleCategoryWrapper}>
                        <h2 className={currentCategoryTittle}>
                            {selectedCourseState.chosenCategoryData.name}
                        </h2>
                        <div className={currentCategoryDescription}>
                            {selectedCourseState.chosenCategoryData.shortDescription}
                        </div>
                    </div>

                    <section className={info}>
                        {courseDescription && courseDescription.length > 0 && (
                            <section>
                                <div className={subTitle}>Opis</div>
                                <Separator className={separator} />
                                <div>
                                    <Markdown>{courseDescription}</Markdown>
                                </div>
                            </section>
                        )}

                        {clues && clues.length > 0 && (
                            <section>
                                <div className={subTitle}>Wskazówki</div>
                                <Separator className={separator} />
                                <div>{clues}</div>
                            </section>
                        )}
                    </section>

                    {initialCourseChosen &&
                        selectedCourseState.chosenCategoryData.media &&
                        selectedCourseState.chosenCategoryData.media.length > 0 && (
                            <div className={dataToDownloadWrapper}>
                                <div className={dataToDownloadTitle}>Materiały do pobrania</div>
                                <ul className={dataToDownloadItems}>
                                    {selectedCourseState.chosenCategoryData.media.map(
                                        (file, index) => (
                                            <li
                                                key={`index-file-to-download--${index}`}
                                                className={downloadItem}
                                            >
                                                <DownloadBlock file={file} />
                                                {!file.uri && (
                                                    <button
                                                        className={restrictedFileButton}
                                                        onClick={handleShowAddToCartModal}
                                                    >
                                                        <IconLock />
                                                    </button>
                                                )}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        )}

                    {currentCategory && currentCategory.movies.length > 0 && (
                        <CoursesMovieGroup
                            className={movieGroup}
                            courseData={selectedCourseState.data}
                            onAddToCart={handleAddToCart}
                            onSeeMore={handleNavigateToProduct}
                            onRestrictedMovie={handleShowAddToCartModal}
                        />
                    )}

                    {movieData.description && movieData.description.length > 0 && (
                        <section className={movieCourseDescription}>
                            <div className={subTitle}>Opis</div>
                            <Separator className={separator} />
                            <div>{movieData.description}</div>
                        </section>
                    )}

                    {movieData.information && movieData.information.length > 0 && (
                        <section className={movieAdditionalInformation}>
                            <div className={subTitle}>Dodatkowe informacje</div>
                            <Separator className={separator} />
                            <div>{movieData.information}</div>
                        </section>
                    )}
                </>
            )}
        </Main>
    );
};
export default Course;
