import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { types } from './order.actions';

const {
    SET_ORDER_CONSENTS,
    CLEAR_ORDER_DATA,
    SET_ORDER_DISCOUNT_CODE,
    SET_DELIVERY_METHODS,
    SET_CURRENT_DELIVERY_METHOD,
    SET_CHOSEN_XSELLING_PROMOTIONS,
    SET_PAY_BY_POINTS_ITEM,
    REMOVE_PAY_BY_POINTS_ITEM,
    SET_PAY_BY_POINTS_BALANCE,
    SET_ORDER_REGISTER_DATA,
} = types;

export const initialState = {
    consents: [],
    discountCode: '',
    delivery: {
        current: '',
        methods: null,
    },
    chosenXselling: {},
    payByPoints: {
        balance: 0,
        items: {},
    },
    registerUser: false,
    userData: {
        email: '',
        firstName: '',
        lastName: '',
    },
};

function orderReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case SET_ORDER_CONSENTS:
            return {
                ...state,
                consents: [...payload],
            };
        case SET_DELIVERY_METHODS:
            return {
                ...state,
                delivery: {
                    ...state.delivery,
                    methods: payload,
                },
            };
        case SET_CURRENT_DELIVERY_METHOD:
            return {
                ...state,
                delivery: {
                    ...state.delivery,
                    current: payload,
                },
            };
        case SET_ORDER_REGISTER_DATA:
            console.log(payload);
            return {
                ...state,
                ...payload,
            };
        case CLEAR_ORDER_DATA:
            return initialState;
        case SET_ORDER_DISCOUNT_CODE:
            return {
                ...state,
                discountCode: payload,
            };
        case SET_CHOSEN_XSELLING_PROMOTIONS:
            return {
                ...state,
                chosenXselling: payload,
            };
        case SET_PAY_BY_POINTS_ITEM:
            return {
                ...state,
                payByPoints: {
                    ...state.payByPoints,
                    items: {
                        ...state.payByPoints.items,
                        [payload.itemId]: {
                            ...state.payByPoints.items[payload.itemId],
                            ...payload,
                        },
                    },
                },
            };
        case REMOVE_PAY_BY_POINTS_ITEM: {
            const newItems = { ...state.payByPoints.items };
            delete newItems[payload.itemId];

            return {
                ...state,
                payByPoints: {
                    ...state.payByPoints,
                    balance:
                        state.payByPoints.balance -
                        (state.payByPoints.items[payload.itemId]?.amount || 0),
                    items: newItems,
                },
            };
        }
        case SET_PAY_BY_POINTS_BALANCE:
            return {
                ...state,
                payByPoints: {
                    ...state.payByPoints,
                    balance: state.payByPoints.balance + payload,
                },
            };
        default:
            return state;
    }
}

const persistConfig = {
    key: 'order',
    storage,
    blacklist: ['discountCode'],
    stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, orderReducer);
